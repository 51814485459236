<template>
  <div class="home" user-drag="none">
    <!-- <img class="home_bg" src="../assets/home_img.png"> -->
    
    <div class="con_b" id="starBg" style="position: unset">
      <!-- 头部导航 -->
      <div style="width: 100%; position: absolute; top: 0; left: 0;">
        <HeaderModel z_active="0"/>
      </div>

      <HomeTop v-show="showTop"></HomeTop>

      </div>
      <div class="con_b" style="position: unset;" v-show="showTop">


      <div style="width: 100%; min-width: 1200px; background-color: #f3f3f3; padding: 100px 0 50px; box-sizing: border-box;">
          <div style="width: 1200px; margin: 0 auto">
            <div class="tboxb" style=" width: 1000px; margin: 0 auto;">
              <el-image :src="home_tbox" fit="cover" style="text-align: center; width: 1000px;"></el-image>
              <div class="dis_column tboxbBtmb" style="width: 270px;">
                <div class="tboxbBtmbTitle">中天汇宇简介</div>
                <div class="tboxbBtmbTitleBottom">Zoom Ten International Inc.</div>
              </div>
            </div>

            <div class="dis_column home_box_b">
              <div class="zhome_box">
                <el-image :src="home_box" fit="cover" style="width: 100%; height: 100%"></el-image>
                <el-image :src="home_pointer" fit="cover" class="home_pointer"></el-image>
                <el-image :src="home_dian" class="home_dian" fit="cover"></el-image>

              </div>
              <el-image :src="home_box_btm" fit="cover" class="zhome_box_btm"></el-image>

              <div class="f_13" style="color: #3A3C3E; line-height: 2; opacity: .9; letter-spacing: 1px; margin: 30px 0 0; font-size: 15px;">{{ $t('home.h1') }}</div>

              <div class="dis homeBox_po1">
                <div class="home_box1 dis_center">企业愿景</div>
                <div class="f_14" style="color: #04CFB9; line-height: 2; margin-left: 18px;">
                    构建一切皆有价值的投资世界<br>
                    让投资创造财富狂潮<br>
                    成为全球卓越的投资企业</div>
              </div>

              <div class="dis homeBox_po2">
                <div class="f_14" style="color: #115EFA; line-height: 2; margin-right: 18px;">
                  承诺企业 忠诚履职<br>
                  凝心聚力 同肩同责<br>
                  聚焦细节 追求极致<br>
                  引领趋势 无限探索</div>
                <div class="home_box2 dis_center">企业价值观</div>
              </div>

              <div class="dis homeBox_po3">
                <div class="home_box2 dis_center">企业使命</div>
                <div class="f_14" style="color: #115EFA; line-height: 2; margin-left: 18px;">
                  用投资改写时代<br>
                  为世界注入不凡</div>
              </div>


            </div>

          </div>
      </div>

      <div class="development_history dis_column">
          <div class="tboxb" style=" width: 1000px; margin: 0 auto;">
            <el-image :src="home_tbox2" fit="cover" style="text-align: center; width: 1000px;"></el-image>
            <div class="dis_column tboxbBtmb" style="width: 270px;">
              <div class="tboxbBtmbTitle2" style="color: #fff;">发展历程</div>
              <div class="tboxbBtmbTitleBottom" style="color: #fff;">Development History.</div>
            </div>
          </div>

          <div style="margin-top: 80px;"
          :style="$i18n.locale== 'en'? '': 'align-items: flex-end;'"
          :class="$i18n.locale== 'en'? 'dis_column': 'dis_center'">
            <div class="f_20 bold" style="color: #fff; letter-spacing: 3px;">2020-2024年</div>
            <div class="f_14" style="color: #fff; opacity: .98; margin-left: 18px; letter-spacing: 2px;">{{ $t('home.h2') }}</div>
          </div>
        
      </div>


      <div style="width: 100%; height: 650px; background-color: #f3f3f3; padding-top: 60px; box-sizing: border-box;">
        <history_lineVue></history_lineVue>
      </div>

      
      <FooterModel></FooterModel>
      
    </div>

    
    <!-- <a href="https://beian.miit.gov.cn" target="_blank" class="ip">{{ $t('footer.btm_text1') }}</a> -->
  </div>
</template>

<script>

import HeaderModel from '@/components/HeaderModel.vue';

import HomeTop from '@/views/homeView/BgHomeView.vue';

import home_tbox from '@/assets/home/home_tbox.png';
import home_box from '@/assets/home/box.png';
import home_box_btm from '@/assets/home/box_btm.png';
import home_pointer from '@/assets/home/home_pointer.png';
import home_dian from '@/assets/home/home_dian.png';
import home_box1 from '@/assets/home/home_box1.png';
import home_box2 from '@/assets/home/home_box2.png';

import home_tbox2 from '@/assets/home/home_tbox2.png'

import history_lineVue from './history_line/history_line.vue';

import FooterModel from '@/components/FooterModel.vue';


export default {
  name: 'HomeView',
  components: {
    HeaderModel,
    HomeTop,
    history_lineVue,
    FooterModel
  },
  data(){
    return{
      home_tbox,   //标题图片
      home_box,   //盒子图片
      home_box_btm, //盒子底部
      home_pointer, //盒子手指
      home_dian, //盒子上的水波纹点
      home_box1,  //价值观背景
      home_box2,   //价值观背景

      home_tbox2,  //标题图片2

      showTop: false,
    }
  },
  beforeCreate(){
    document.title= '中天汇宇投资集团'
  },  
  mounted(){

    setTimeout(() => {
      this.showTop= true
    }, 100);

    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      // console.log('跳转到手机')
      let _r = this.$route.name;
      console.log(_r)
      if (_r === 'home'){
        this.$router.replace('/wap')
      }
      return;
    }



    // document.body.style.overflowX= 'hidden';
    let _w= document.body.clientWidth;
    
    if(_w< 1300){
      if(this.$refs.con_center_b){
        this.$refs.con_center_b.style.zoom= 0.7
        this.$refs.con_center_b.style.justifyContent= 'space-evenly'
      }
      

    }
    
  },  
  beforeDestroy(){
    document.body.style.overflow= 'auto';
    // console.log(document.getElementById('starBg'))
    // document.getElementById('starBg').removeEventListener('mousemove', onMouseMove, false);

  }
}
</script>


<style scoped>
  html{
    overflow: auto;
  }
  body{
    overflow: hidden;
    user-select: none;
  }
  
  .home{
    width: 100%;

  }
  .home_bg{

    width: auto;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -100;
    overflow: hidden;
}

  .con_b{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* z-index: 1; */
  }

  .con_center_b{
    width: 100%;
    min-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15.037vh calc((100vw - 1200px) /2) 18.518vh;
    /* padding: 130px calc((100vw - 1200px) /2) 200px; */
    box-sizing: border-box;
    /* overflow: hidden; */
  }




  
  .dis_column{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .light_img{
    width: 830px;
    height: 346px;
    position: absolute;
    top: -78px;
    right: -230px;
    z-index: -1;
  }
  .star{
    width: 330px;
    height: 256px;
    position: absolute;
    top: -125px;
    right: 23px;
    z-index: -1;
  }


  .ip{
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #fff;
    opacity: .3;
    cursor: pointer;
    font-size: 12px;
    text-decoration: unset;
  }

  .tboxb{
    position: relative;
    margin: 0 auto;
  }
  .tboxbBtmb{
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .tboxbBtmbTitle,.tboxbBtmbTitle2{
    font-size: 24px;
    letter-spacing: 6px;
    font-weight: bold;  
    position: relative;
  }
  .tboxbBtmbTitle::after,.tboxbBtmbTitle2::after{
    position: absolute;
    left: calc(50% - 120px);
    bottom: -5px;
    width: 240px;
    height: 12px;
    background-color: #125dfb;
    z-index: -1;
    content: '';
  }
  .tboxbBtmbTitle2::after{
    background-color: #527edb;
  }
  .tboxbBtmbTitleBottom{
    font-size: 12px;
    color: #3373fa;
    letter-spacing: 2px;
    margin-top: 16px;
  }
  .home_box_b{
    position: relative;
    margin-top: 40px;
  }
  .zhome_box{
    width: 306px;
	  height: 267px;
    position: relative;
    animation: float-animation 2s linear infinite;
    z-index: 10;
  }
  @keyframes float-animation {
    0% {
      top: 0; /* 动画开始时元素位于顶部 */
    }
    30%{
      top: 4.5px;
    }
    50% {
      top: 6px; /* 动画中间时元素向下移动 */
    }
    70%{
      top: 4.5px;
    }
    100% {
      top: 0; /* 动画结束时元素回到原始位置 */
    }
  }

  .zhome_box_btm{
    width: 823px;
	  height: 234px;
  }

  .home_pointer{
    width: 62px;
	  height: 58px; 
    position: absolute;
    right: 55px;
    top: 60px;
    z-index: 2;
  }
  .home_dian{
    width: 26px; 
    height: 26px;
    animation: warn 1.5s ease-out 0s infinite;
    position: absolute;
    top: 64px;
    right: 92px;
    z-index: 1;
  }
  @keyframes warn {
      0% {
            transform: scale(0.5);
            opacity: 1;
      }

      30% {
            opacity: 1;
      }

      100% {
            transform: scale(1.4);
            opacity: 0;
      }
  }

  
  .home_box1{
    background: url('@/assets/home/home_box1.png') top left no-repeat ;
    color: #04CFB9;
    
  }
  .home_box2{
    background: url('@/assets/home/home_box2.png') top left no-repeat ;
    color: #115EFA;
  }
  .home_box1,.home_box2{
    width: 117px;
	  height: 106px;
    background-size: 100% 100%;
    font-size: 14px;
    padding: 0 13px 0 0;
    box-sizing: border-box;
  }
  .homeBox_po1,.homeBox_po2,.homeBox_po3{
    position: absolute;
  }
  .homeBox_po1{
    top: 50px;
    right: 90px;
  }
  .homeBox_po2{
    top: 130px;
    left: 160px;
  }
  .homeBox_po3{
    top: 190px;
    right: 110px;
  }

  .development_history{
    width: 100%;
	  height: 535px;
    background: url('@/assets/home/his.jpg') no-repeat center top;
    background-size: cover;
    min-width: 1200px;
  }
</style>