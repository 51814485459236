import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue';
import ContactUs from '../views/ContactUs.vue';
// import WapContactUs from '../views/wap/WapContactUss.vue'


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {

  return originalPush.call(this, location).catch(err => err)

}

const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {

  return RouterReplace.call(this, to).catch(err => err)

}


const routes = [
  {
    path: '/',
    name: 'home',
    meta:{
      title: '中天汇宇投资集团'
    },
    component: HomeView
  },
  {
    path: '/productCases',
    name: 'productCases',
    meta:{
      title: '产品案例'
    },
    component: () => import('../views/ProductCases.vue')
  },
  {
    path: '/newsList',
    name: 'newsList',
    meta: {
      title: '新闻资讯'
    },
    component: ()=> import('../views/NewsList.vue')
  },
  {
    path: '/joinUs',
    name: 'joinUs',
    meta: {
      title: '加入我们'
    },
    component: ()=> import('../views/JoinUs.vue')
  },
  {
    path: '/jobList',
    name: 'jobList',
    meta: {
      title: '加入我们'  //职位列表
    },
    component: ()=> import('../views/job_detail/JobList.vue')
  },
  {
    path: '/jobDetail',
    name: 'jobDetail',
    meta: {
      title: '加入我们'  //职位详情
    },
    component: ()=> import('../views/job_detail/JobDetail.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    meta:{
      title: '联系我们'
    },
    component: ContactUs
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    meta:{
      title: '关于我们'
    },
    component: ()=> import('../views/AboutUs.vue')
  },
  {
    path: '/newsDetails',
    name: 'newsDetails',
    meta: {
      title: '新闻详情'
    },
    component: ()=> import('../views/NewsDetails.vue')
  },

    //手机端
  {
    path: '/wap',
    name: 'wap',
    meta: {
      title: '中天汇宇投资集团'
    },
    component: ()=> import('../views/wap/Home.vue'),
  },
  {
    path: '/wapProductCases',
    name: 'wapProductCases',
    meta: {
      title: '产品案例'
    },
    component: ()=> import('../views/wap/ProductCases.vue')
  },
  {
    path: '/wapAboutUs',
    name: 'wapAboutUs',
    meta:{
      title: '关于我们'
    },
    component: ()=> import('../views/wap/WapAboutUs.vue')
  },
  {
    path: '/wapNewsList',
    name: 'wapNewsList',
    meta:{
      title: '新闻资讯'
    },
    component: ()=> import('../views/wap/WapNewsList.vue')
  },
  {
    path: '/wapJoinUs',
    name: 'wapJoinUs',
    meta:{
      title: '加入我们'
    },
    component: ()=> import('../views/wap/WapJoinUs.vue')
  },
  {
    path: '/wapContactUs',
    name: 'wapContactUs',
    meta:{
      title: '联系我们'
    },
    component: ()=> import('../views/wap/WapContactUss.vue')
    // component: WapContactUs
  },
  {
    path: '/wapNewsDetail',
    name: 'wapNewsDetail',
    meta:{
      title: '新闻详情'
    },
    component: ()=> import('../views/wap/WapNewsDetail.vue')
    // component: WapContactUs
  },
  {
    path: '/wapJobList',
    name: 'wapJobList',
    meta:{
      title: '加入我们'    //职位列表
    },
    component: ()=> import('../views/wap/WapJobList.vue')
    // component: WapContactUs
  },
  {
    path: '/wapJobDetail',
    name: 'wapJobDetail',
    meta: {
      title: '加入我们'  //职位详情
    },
    component: ()=> import('../views/wap/WapJobDetail.vue')
  },
  
  
  {
    path: "*",
      name: "NotFound",
      component: () => import("@/views/NotFound.vue"),
  }
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})




export default router
