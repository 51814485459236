import { render, staticRenderFns } from "./HeaderModel.vue?vue&type=template&id=087d9816&scoped=true&"
import script from "./HeaderModel.vue?vue&type=script&lang=js&"
export * from "./HeaderModel.vue?vue&type=script&lang=js&"
import style0 from "@/common/header.css?vue&type=style&index=0&id=087d9816&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "087d9816",
  null
  
)

export default component.exports