module.exports= {
    language: {
        name: 'English',
        wap_name: 'EN'
    },
    //头部导航
    header: {
        text1: 'Home',
        text2: 'Product',
        text3: 'About us',
        text4: 'News',
        text5: 'Join us',
        text6: 'Contact us',
    },


    //底部导航
    footer: {
        type1: 'website：https://www.zhongtianhuiyu.com',
        type2: 'Email：ztianhuiyu@163.com',
        type3: 'telephone：0451-82715555',
        type4: 'Service time：Working hours：9：00-17：30',
        type5: 'address：36 Songbei Road, Songbei District, Harbin City, Heilongjiang Province',
        jpg_text1: 'WX public',
        jpg_text2: 'Microblog',
        jpg_text3: 'Red Book',
        jpg_text4: 'Zhihu',
        btm_text1: 'Black ICP No. 2021011899 - 1',
    },


    //首页
    home: {
        mouse1: 'Mouse wheel',
        mouse2: 'control compass',
        h1: 'Harbin Zhongtian Huiyu Investment Group Co., Ltd. was established in March 2021, the group headquarters is located in the ice city of Harbin, is a research and development, manufacturing, trade, investment as one of the comprehensive enterprise group. Zhongtian Huiyu Group is deeply engaged in the field of comprehensive investment, arranging six investment areas: Internet research and development, science and technology entertainment, pet services, beauty and skin care, catering management, and media marketing. After years of in-depth practice, it has formed a brand industry matrix with Huanyu Mutual Entertainment, Muzhiqi Biotechnology, Gao Meiyan Trading, Jingfeng Technology, Food lock catering, Caishi Culture and Media and other companies as the core. At present, the group has invested in a number of high-quality projects in cutting-edge fields at home and abroad.',
        h2: 'Zhongtian Huiyu rapid development, more than ten industrial subsidiaries have been established',
        h3: "From 2023 to 2024, the Group's strategic territory will expand to the Yangtze River Delta and Pearl River Delta regions. Harbin Hachima Pet Service Co., LTD., Hangzhou Jingfeng Technology Co., LTD., Hangzhou Siyu Information Software Technology Co., LTD., Hangzhou Hachima Pet Service Co., LTD., Guangzhou Gaomeiyan Biotechnology Co., LTD., Harbin Qitanhe Catering Management Co., LTD., Shenzhen branch, have been established."
    },

    //关于我们
    about_us: {
        top_title1: 'Enterprise vision',
        top_dec1: 'Skilled in expertise, done in detail; Success lies in diligence and sincerity.',
        top_title2: 'Corporate mission',
        top_dec2: 'Equal emphasis on attack and defense, practical action by all staff, activity objectives, and service orientation.',
        top_title3: 'Enterprise values',
        top_dec3: '"Never lose originality, innovation comes first,  extensive observation leads to engagement, and thick  accumulation leads to thin development"',
        company_text: 'Harbin Zhongtian Huiyu Investment Group Co., LTD. (hereinafter referred to as the "Group") was established in March 2021, headquartered in the beautiful Harbin Ice City. As a comprehensive enterprise group, the group integrates manufacturing, research and development, trade and investment, involving the Internet, modern services, comprehensive pet services, beauty and skin care, catering management, science and technology entertainment and other industrial investment fields. Adhering to the business philosophy of "integrity, innovation, science and technology, stability and long term", and with the vision of "creating the most responsible investment service provider in China", the group is committed to the glorious mission of cultivating and developing the industry with resource advantages, expanding the company itself, creating value, serving the society and achieving employees. In the future, the Group will adhere to the development model of "Group operation - industrial investment - financial services - resource integration", and is committed to becoming a modern enterprise group with strong strength, excellent assets, standard management, good benefits and great influence. The Group will strive to build a solid investment company integrating science and technology construction, entity operation services and financial investment management.',
        btm_text1: 'Service hotline',
        btm_text2: 'Harbin Zhongtian Huiyu Investment Group Co., Ltd., focusing on investment in science and technology industry and application fields, has a high-quality professional team, a scientific man-agement system, and adheres to growing together with a res-ilien',
        btm_text3: 'Address: No. 36, Songbei Avenue, Songbei District, Harbin, Heilongjiang Province',
        btm_text4: 'service hours：working days：9：00am-17：30pm',
        btm_text5: 'email：zhongtianhuiyu@163.com',
        tab1: 'Company profile',
        tab2: 'Corporate culture',

        h2: 'Founded in March 2021, Harbin Zhongtian Huiyu International Investment Group Co., Ltd. is deeply engaged in six investment areas: Internet research and development, science and technology entertainment, pet services, beauty and skin care, food and beverage management, and media marketing. After years of in-depth practice, it has formed an investment industry matrix with Huanyu Mutual Entertainment, Muzhiqi Biotechnology, Gao Meiyan Trading, Jingfeng Technology, Food lock catering, Color Culture Media and other companies as the core. In 2023, the business area of the Group will expand to the Yangtze River Delta and Pearl River Delta regions, and the strategic territory will gradually cover the whole country. In 2024, the industrial layout of Zhongtian Huiyu will look globally, and the first overseas subsidiary will be established in the United States to continue to build a global industrial alliance.',
        h3: 'Harbin Zhongtian Huiyu International Investment Group Co., LTD., focusing on science and technology industry',
        h4: 'Investment and application field, with high-quality professional team, scientific management system,',
        h5: 'Insist on growing together with a tough and intelligent entrepreneurial team.',
    },

    //新闻资讯
    newsList: {
        tab1: 'Company News',
        tab2: 'Industry News',
    },


    //新闻详情
    newsDetail: {
        time: 'Time',
        back: 'Return to list'
    },


    //联系我们
    contactUs: {
        title: 'FIND US ON AMAPS',
        item_text1: 'E-mail',
        item_tip1: 'zhongtianhuiyu@163.com',
        item_text2: 'ADDRESS',
        item_tip2: 'No. 36, Songbei Avenue, Songbei District, Harbin Heilongjiang Province.',
        item_text3: 'service time',
        item_tip3: 'weekday：9：00-17：30',
        item_text4: 'telephone',
        item_tip4: '0451-82715555',
        placeholder1: 'your name',
        placeholder2: 'telephone number',
        placeholder3: 'message',
        submit: 'Submit',
        h1: 'Zoom Ten International Inc.',
        h2: 'If you are interested in going to Harbin Zhongtian Huiyu International Investment Group Co., LTD., please make an appointment one day in advance, welcome to your arrival.',
        h3: 'telephone'

    },


}